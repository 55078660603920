<!--
 * @Author: felixli
 * @Date: 2023-11-10 14:16:08
 * @Description: 
-->
<template>
  <div class="task">
    <div class="task-box">
      <div
        class="task-box-item"
        v-for="item in taskList"
        :key="item.id"
        @click="goCourseDetail(item)"
      >
        <div class="task-box-item-header">
          <img :src="item.coverImage" alt="" />
        </div>
        <div class="task-box-item-content">
          <p class="title">{{ item.name }}</p>
          <div class="percentage">
            <!-- <span>{{ item.statusStr }}</span> -->
            <el-progress
              color="$WDPrimaryColor"
              define-back-color="#E9E9E9"
              text-color="$WDPrimaryColor"
              :percentage="item.studyProcess"
            ></el-progress>
          </div>
        </div>
      </div>
    </div>
    <article v-if="noData" class="ListEmptyData">
      <img src="../../../assets/images/emptyData.png" alt />
      <p>您还没有学习内容~</p>
    </article>
    <p class="loadingmore" v-if="loading">加载中...</p>
    <p class="loadingmore" v-if="noMore">- 已加载全部内容 -</p>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "studyTask",
  props: {},
  components: {},
  data() {
    return {
      studyProcess: 75,
      taskList: [],
      paging: {
        params: {
          pageNum: 1,
          pageSize: 10,
        },
        total: 0,
      },
      loading: false,
      noData: false,
    };
  },
  watch: {},
  computed: {
    noMore() {
      return (
        this.taskList.length === this.paging.total && this.paging.total !== 0
      );
    },
  },
  created() {
    this.getTaskList();
  },
  mounted() {
    $(window).scroll(() => {
      if (
        Math.round($(window).scrollTop()) ===
        $(document).height() - $(window).height()
      ) {
        setTimeout(this.reachBottom(), 1000);
      }
    });
  },
  methods: {
    async getTaskList() {
      let params = {
        pageNum: this.paging.params.pageNum,
        pageSize: this.paging.params.pageSize,
        taskStatus: "",
        type: 2,
      };
      const { code, data } = await this.$api.learn.findTaskList({ params });
      if (code === 0) {
        let ids = [];
        if (data?.list?.length) {
          this.noData = false;
          data.list.forEach((item) => {
            ids.push(item.id);
          });
        } else {
          this.noData = true;
        }
        if (this.paging.params.pageNum > 1) {
          this.taskList = this.taskList.concat(data.list);
        } else {
          this.taskList = data.list || [];
        }
        await this.findTaskLearningInfo(ids.join(","));
        this.paging.total = data?.total;
        // this.paging.params.pageNum = data?.pageNum;
        // this.paging.params.pageSize = data?.pageSize;
      }
    },
    reachBottom() {
      if (
        this.paging.total >
        this.paging.params.pageNum * this.paging.params.pageSize
      ) {
        this.paging.params.pageNum = this.paging.params.pageNum + 1;
        this.loading = true;
        this.getTaskList("reachBottom");
      } else {
        this.loading = false;
      }
    },
    goCourseDetail({ id }) {
      this.$router.push(`/learn/course/detail?id=${id}`);
    },
    // 二次渲染 学习相关信息
    async findTaskLearningInfo(ids) {
      let params = {
        param: ids,
      };
      await this.$api.learn.findTaskLearningInfo({ params }).then((res) => {
        if (res.data) {
          this.taskList.forEach((item) => {
            for (let key in res.data) {
              if (Number(key) === item.id) {
                item.contentTypeSet = res.data[key].contentTypeSet;
                item.studyProcess = res.data[key].studyProcess;
                // item.courseItemTypeList = res.data[key].courseItemTypeList.filter((item) => { return item.type !== 2 });
                item.courseItemTypeList = res.data[key].courseItemTypeList;
                item.courseNum = res.data[key].courseNum;
                item.learnTotalTime = res.data[key].learnTotalTime;
                // console.log(item.courseItemTypeList, 'item.courseItemTypeList')
              }
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.task-box {
  display: flex;
  flex-wrap: wrap;
  &-item {
    width: 258px;
    height: 223px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(34, 98, 188, 0.1);
    border-radius: 8px;
    margin-right: 40px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    &:nth-child(4n) {
      margin-right: 0;
    }
    &-header {
      width: 100%;
      height: 148px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 8px 8px 0 0;
        object-fit: cover;
      }
    }
    &-content {
      flex: 1;
      width: 100%;
      padding: 5px 8px 0 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .title {
        width: 192px;
        height: 26px;
        line-height: 28px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .percentage {
        height: 45px;
        display: flex;
        align-items: center;
        span {
          font-size: 12px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #333333;
          padding-right: 8px;
        }
        .el-progress {
          flex: 1;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
::v-deep {
  .el-progress {
    display: flex;
    align-items: center;
    .el-progress-bar {
      margin-right: 0;
      padding-right: 14px;
    }
    .el-progress-bar__outer {
      // width: 372px;
      height: 10px !important;
    }
    .el-progress__text {
      margin-left: 0;
      margin-right: 10px;
    }
  }
}
</style>
