<template>
  <div class="courseList">
    <div class="item" v-for="item in courseList" :key="item.id">
      <div class="item-status">
        <img class="item-status-img" :src="item.status === 1
          ? liveStart
          : item.status === 2
            ? liveBack
            : liveNo
          " alt="" />
        <p class="item-status-title">
          {{
            item.status === 1
              ? "直播中"
              : item.status === 2
                ? "已结束"
                : "未开始"
          }}
        </p>
      </div>
      <img :src="item.coverUrl" class="cover" alt="" />
      <div class="info info-content">
        <p class="name">{{ item.name }}</p>
        <div class="time">
          <p class="time-item">开始时间：{{ item.startTime }}</p>
        </div>
      </div>
      <div class="botton" v-if="item.status === 1">
        <p class="btn" @click="seeLive(item)">
          进入直播
        </p>
      </div>
      <div class="botton" v-if="item.status === 2 && item.isReplay === 1 && item.replayUrl">
        <p class="btn" @click="seeLive(item)">查看回放</p>
      </div>
    </div>
    <article v-if="noData" class="ListEmptyData">
      <img src="../../../assets/images/emptyData.png" alt />
      <p>您还没有学习内容~</p>
    </article>
    <p class="loadingmore" v-if="loading">加载中...</p>
    <p class="loadingmore" v-if="noMore">- 已加载全部内容 -</p>
  </div>
</template>

<script>
import liveStart from "@/assets/images/live/live_start.png";
import liveNo from "@/assets/images/live/live_no.png";
import liveBack from "@/assets/images/live/live_back.png";
import $ from "jquery";
import { goLiveHome } from "@/utils/liveToHome.js";
export default {
  props: {
    taskStatus: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      noData: null,
      paging: {
        params: {
          pageNum: 1,
          pageSize: 10,
        },
        total: 0,
      },
      loading: false,
      courseList: [],
      liveStart,
      liveNo,
      liveBack,
    };
  },
  computed: {
    noMore() {
      return (
        this.courseList.length === this.paging.total && this.paging.total !== 0
      );
    },
  },
  created() {
    this.findTaskList();
  },
  mounted() {
    $(window).scroll(() => {
      //判断是否滑动到页面底部
      if (
        Math.round($(window).scrollTop()) ===
        $(document).height() - $(window).height()
      ) {
        // TODO 滑动到底部时可请求下一页的数据并加载，加载可使用append方法
        // console.log('页面底部')

        setTimeout(this.reachBottom(), 1000);
      }
    });
  },
  methods: {
    reachBottom() {
      if (
        this.paging.total >
        this.paging.params.pageNum * this.paging.params.pageSize
      ) {
        this.paging.params.pageNum = this.paging.params.pageNum + 1;
        this.loading = true;
        this.findTaskList("reachBottom");
      } else {
        this.loading = false;
      }
    },
    // 列表初始化
    findTaskList() {
      let params = {};
      if (this.taskStatus != "") {
        let count = this.taskStatus - 1;
        params = { status: count };
      } else {
        params = {};
      }
      this.$api.live
        .liveList(
          params,
          this.paging.params.pageNum,
          this.paging.params.pageSize
        )
        .then((res) => {
          if (res.data) {
            if (res.data.list && res.data.list.length) {
              this.noData = false;
            } else {
              this.noData = true;
            }
            if (this.paging.params.pageNum > 1) {
              this.courseList = this.courseList.concat(res.data.list);
            } else {
              this.courseList = res.data.list || [];
            }
            let ids = [];
            this.courseList.forEach((item) => {
              ids.push(item.id);
            });
            this.paging.total = res.data.total;
          }
        });
    },
    seeLive(val) {
      if (val.status === 1 || (val.status === 2 && val.isReplay === 1)) {
        val.contentId = val.liveId;
        val.contentStatus = val.status;
        goLiveHome(this, val);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.courseList,
.examList {
  .item {
    // height: 168px;
    display: flex;
    padding: 24px 32px;
    position: relative;
    background: #fff;
    margin-bottom:16px;
    border-radius: 6px;
    width: 100%;
    box-sizing: border-box;

    .new-icon {
      width: 62px;
      position: absolute;
      top: 13px;
      left: -1px;
    }

    .item-status {
      position: absolute;
      top: 32px;
      left: 40px;
      width: 72px;
      height: 24px;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 6px;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-img {
        width: 24px;
        height: 24px;
        border-radius: 6px;
      }

      &-title {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 400;
        color: #FFFFFF;

      }
    }

    .cover {
      width: 213px;
      height: 120px;
      border-radius: 8px;
      margin-right: 23px;
      object-fit: cover;
    }

    .info {
      flex: 1;

      &-content {

        display: flex;
        flex-direction: column;

        .name {
          height: 24px
        }
      }

      .name {
        margin-top: 4px;
        display: flex;
        align-items: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        line-height: 25px;
        text-align: left;
        font-style: normal;

        img {
          height: 23px;
          margin-right: 8px;
        }

        span {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 25px;
        }
      }

      .hourCredit {
        display: flex;
        align-items: center;
        margin-top: 4px;

        .icon {
          font-size: 16px;
          margin-right: 5px;
        }

        span {
          font-size: 13px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #333333;
          line-height: 18px;

          span {
            color: $WDPrimaryColor;
          }
        }
      }

      .time {
        margin-top: 8px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
        text-align: left;
        font-style: normal;

        &-item {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
        }
      }

      .examTime {
        margin-top: 24px;
        margin-bottom: 8px;
        color: #333;
      }

      .label {
        margin-top: 10px;

        span {
          display: inline-block;
          vertical-align: middle;
          background: #EDEFF2;
          border-radius: 3px;
          width: 77px;
          height: 24px;
          text-align: center;
          line-height: 24px;
          margin-right: 12px;
          font-size: 12px;
          font-family: PingFang-SC-Light, PingFang-SC;
          font-weight: 300;
          color: #333333;
        }
      }

      .progress {
        margin-top: 16px;
        display: flex;
        align-items: center;
        flex: 1;

        .hour,
        ::v-deep .el-progress__text {
          font-size: 12px !important;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: $WDPrimaryColor;
          line-height: 17px;
        }

        ::v-deep .el-progress {
          display: flex;
          align-items: center;

          .el-progress-bar {
            margin-right: 0;
            padding-right: 14px;
          }

          .el-progress-bar__outer {
            width: 372px;
            height: 10px !important;
          }

          .el-progress__text {
            margin-left: 0;
            margin-right: 10px;
          }
        }
      }

    }

    .examBotton {
      flex: 1;
      display: flex;
      justify-content: space-between;

      .source {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        display: flex;
        flex: 1;
        width: 0;
        margin-right: 24px;

        .sourceName {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

      }

      .btn {
        width: 148px;
        height: 44px;
        background: $WDPrimaryColor;
        border-radius: 2px;
        text-align: center;
        cursor: pointer;
        border-radius: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 44px;
      }
    }

    .botton {
      position: absolute;
      bottom: 24px;
      right: 32px;
      width: 148px;
      height: 44px;
      background: $WDPrimaryColor;
      border-radius: 22px;
      text-align: center;
      cursor: pointer;

      .btn {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 44px;
        border-radius: 22px;
      }
    }

    .botton-end {
      position: absolute;
      bottom: 24px;
      right: 32px;
      width: 148px;
      height: 44px;
      background: #F6F7F9;
      border-radius: 22px;
      text-align: center;
      cursor: pointer;

      .btn {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 44px;

      }
    }
  }
}
</style>
